import { useStorage } from "@vueuse/core";

type Token = string | null;
const authStorage = useStorage<{
  token: Token | undefined;
  refreshToken: Token | undefined;
  orgTokens: Record<string, Token | undefined> | null | undefined;
}>("auth", {
  token: null,
  refreshToken: null,
  orgTokens: null,
});

const isLoggedIn = computed(() => !!authStorage.value.token);

export const useAuthStorage = () => {
  const setTokens = ({
    token,
    refreshToken,
    orgToken,
    orgId,
  }: {
    token?: string | null;
    refreshToken?: string | null;
    orgToken?: string | null;
    orgId?: string;
  }) => {
    if (token != null) {
      authStorage.value.token = token;
    }

    if (refreshToken != null) {
      authStorage.value.refreshToken = refreshToken;
    }

    if (orgToken != null && orgId != null) {
      if (authStorage.value.orgTokens == null) {
        authStorage.value.orgTokens = {};
      }

      authStorage.value.orgTokens![orgId] = orgToken;
    }
  };

  const resetTokens = () => {
    authStorage.value.token = undefined;
    authStorage.value.refreshToken = undefined;
    authStorage.value.orgTokens = undefined;
  };

  return {
    authStorage,
    isLoggedIn,
    setTokens,
    resetTokens,
  };
};
